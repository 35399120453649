import { graphql } from "gatsby"
import * as React from "react"

import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SuccessPage = () => {
  const { t } = useTranslation()
  return (
    <Layout navbarTheme="dark">
      <Seo title="Success" />
      <div className="bg-white text-white relative lg:py-20">
        <div className="container text-center mx-auto  py-10 lg:px-10 max-w-4xl">
          <h1 className="text-3xl lg:text-5xl p-10 font-bold text-blue">
            {t("successText")}
          </h1>
        </div>
      </div>
    </Layout>
  )
}
export default SuccessPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`